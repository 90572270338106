import React, { Suspense } from "react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import lazyRetry from "utils/LazyRetry";

import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege, { hasReadAccess } from "types/common/PortalPrivilege";

import PersonSearch from "@mui/icons-material/PersonSearch";
import FavoritesIcon from "@mui/icons-material/StarOutline";

import LoadingProgress from "components/common/widgets/LoadingProgress";

const UserBrowser = React.lazy(lazyRetry(() => import("components/screens/users/UserBrowser")));
const ErrorLogs = React.lazy(lazyRetry(() => import("components/screens/common/ErrorLogs")));
const UnreadableRequests = React.lazy(lazyRetry(() => import("components/screens/common/UnreadableRequests")));
const UserLogs = React.lazy(lazyRetry(() => import("components/screens/users/Logs")));
const PlayerFriends = React.lazy(lazyRetry(() => import("components/screens/users/Social/Friends")));
const PlayerAchievements = React.lazy(lazyRetry(() => import("components/screens/users/Gamification/Achievements")));
const PlayerGroups = React.lazy(lazyRetry(() => import("components/screens/users/Social/Groups")));
const PlayerRedeemedCodes = React.lazy(lazyRetry(() => import("components/screens/users/Monetization/RedemptionCodes")));
const UserStatistics = React.lazy(lazyRetry(() => import("components/screens/users/CloudData/UserStatistics")));
const UserFiles = React.lazy(lazyRetry(() => import("components/screens/users/Files/UserFiles")));
const UserCustomEntity = React.lazy(lazyRetry(() => import("components/screens/monitoring/CloudData/CustomEntities/UserCustomEntity")));
const TurnByTurnMP = React.lazy(lazyRetry(() => import("components/screens/users/Multiplayer/TurnByTurnMP")));
const Inventory = React.lazy(lazyRetry(() => import("components/screens/users/Monetization/Inventory")));
const Transactions = React.lazy(lazyRetry(() => import("components/screens/users/Monetization/Transactions")));
const OneByOneMP = React.lazy(lazyRetry(() => import("components/screens/users/Multiplayer/OneWayMP")));
const Pricing = React.lazy(lazyRetry(() => import("components/screens/users/Monetization/Pricing")));
const MilestonesAndQuests = React.lazy(lazyRetry(() => import("components/screens/users/Gamification/MilestonesAndQuests")));
const VirtualCurrency = React.lazy(lazyRetry(() => import("components/screens/users/Monetization/VirtualCurrency")));
const UserSummary = React.lazy(lazyRetry(() => import("components/screens/users/UserSummary")));
const UserActivity = React.lazy(lazyRetry(() => import("components/screens/users/UserActivity")));
const UserEntities = React.lazy(lazyRetry(() => import("components/screens/users/CloudData/UserEntities")));
const Attributes = React.lazy(lazyRetry(() => import("components/screens/users/CloudData/Attributes")));
const SecurityLogs = React.lazy(lazyRetry(() => import("components/screens/common/SecurityLogs")));

const routes: PortalRouteDefinition[] = [
    {
        path: "user",
        hasAccess: (session: Session, _currentUser: User | null, _availableCompanies: TeamInfo[], _availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
            return session.appId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_BROWSER");
        },
        label: (i18n: I18n) => i18n._(t`User`),
        icon: null,
        exact: true,
        redirect: "user-browser",
        routes: [
            {
                path: "user-browser/:companyId?/:appId?/:playerId?",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return hasReadAccess(availablePrivileges, "MONITOR_USER_BROWSER");
                },
                label: (i18n: I18n) => i18n._(t`User Browser`),
                icon: <PersonSearch />,
                exact: false,
                component: (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <UserBrowser />
                    </Suspense>
                ),
            },
            {
                path: "favorites",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return false;
                },
                label: (i18n: I18n) => i18n._(t`Favorites`),
                icon: <FavoritesIcon />,
                exact: true,
                routes: [],
            },
            {
                path: "summary",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return session.playerId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_SUMMARY");
                },
                label: (i18n: I18n) => i18n._(t`Summary`),
                icon: null,
                exact: true,
                redirect: "user-summary",
                routes: [
                    {
                        path: "user-summary",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.playerId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_SUMMARY");
                        },
                        label: (i18n: I18n) => i18n._(t`User Summary`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserSummary />
                            </Suspense>
                        ),
                    },
                    {
                        path: "user-activity",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.playerId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_ACTIVITY");
                        },
                        label: (i18n: I18n) => i18n._(t`User Activity`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserActivity />
                            </Suspense>
                        ),
                    },
                    {
                        path: "logs",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.playerId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_LOGS");
                        },
                        label: (i18n: I18n) => i18n._(t`Logs`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserLogs />
                            </Suspense>
                        ),
                    },
                    {
                        path: "recent-errors",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.playerId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_LOGS_ERRORS");
                        },
                        label: (i18n: I18n) => i18n._(t`Recent Errors`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <ErrorLogs context={"player"} />
                            </Suspense>
                        ),
                    },
                    {
                        path: "security-logs",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_SECURITY_LOGS");
                        },
                        label: (i18n: I18n) => i18n._(t`Security Logs`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <SecurityLogs context={"player"} />
                            </Suspense>
                        ),
                    },
                    {
                        path: "unreadable-requests",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.playerId != null && hasReadAccess(availablePrivileges, "MONITOR_USER_LOGS_UNREADABLE");
                        },
                        label: (i18n: I18n) => i18n._(t`Unreadable Requests`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UnreadableRequests context={"player"} />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "data",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return session.playerId != null;
                },
                label: (i18n: I18n) => i18n._(t`Data`),
                icon: null,
                exact: true,
                redirect: "attributes",
                routes: [
                    {
                        path: "attributes",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_ATTRIBUTES");
                        },
                        label: (i18n: I18n) => i18n._(t`Attributes`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Attributes />
                            </Suspense>
                        ),
                    },
                    {
                        path: "custom-entities",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_CUSTOM_ENTITIES");
                        },
                        label: (i18n: I18n) => i18n._(t`Custom Entities`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserCustomEntity />
                            </Suspense>
                        ),
                    },
                    {
                        path: "statistics",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_STATS");
                        },
                        label: (i18n: I18n) => i18n._(t`Statistics`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserStatistics />
                            </Suspense>
                        ),
                    },
                    {
                        path: "user-entities",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_ENTITIES");
                        },
                        label: (i18n: I18n) => i18n._(t`User Entities`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserEntities />
                            </Suspense>
                        ),
                    },
                    {
                        path: "user-files",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_FILES");
                        },
                        label: (i18n: I18n) => i18n._(t`User Files`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <UserFiles />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "gamification",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    const targetApp = availableApps.find((item) => item.appId === session.appId);

                    return session.playerId != null && targetApp?.isGamificationEnabled === true;
                },
                label: (i18n: I18n) => i18n._(t`Gamification`),
                icon: null,
                exact: true,
                redirect: "achievements",
                routes: [
                    {
                        path: "achievements",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_ACHIEVEMENTS");
                        },
                        label: (i18n: I18n) => i18n._(t`Achievements`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <PlayerAchievements />
                            </Suspense>
                        ),
                    },
                    {
                        path: "milestones-and-quests",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_MILESTONES");
                        },
                        label: (i18n: I18n) => i18n._(t`Milestones & Quests`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <MilestonesAndQuests />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "marketplace",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return session.playerId != null;
                },
                label: (i18n: I18n) => i18n._(t`Marketplace`),
                icon: null,
                exact: true,
                redirect: "inventory",
                routes: [
                    {
                        path: "inventory",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_VIRTUAL_ITEMS");
                        },
                        label: (i18n: I18n) => i18n._(t`Inventory`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Inventory />
                            </Suspense>
                        ),
                    },
                    {
                        path: "pricing",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_PRICING");
                        },
                        label: (i18n: I18n) => i18n._(t`Personalized Pricing`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Pricing />
                            </Suspense>
                        ),
                    },
                    {
                        path: "redemption-codes",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_RECEPTIONS_CODES");
                        },
                        label: (i18n: I18n) => i18n._(t`Redemption Codes`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <PlayerRedeemedCodes />
                            </Suspense>
                        ),
                    },
                    {
                        path: "transactions",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_PURCHASES");
                        },
                        label: (i18n: I18n) => i18n._(t`Transactions`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Transactions />
                            </Suspense>
                        ),
                    },
                    {
                        path: "virtual-currency",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_VIRTUAL_CURRENCY");
                        },
                        label: (i18n: I18n) => i18n._(t`Virtual Currency`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <VirtualCurrency />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "multiplayer",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    const targetApp = availableApps.find((item) => item.appId === session.appId);

                    return session.playerId != null && targetApp?.isGamificationEnabled === true;
                },
                label: (i18n: I18n) => i18n._(t`Multiplayer`),
                icon: null,
                exact: true,
                redirect: "one-way-mp",
                routes: [
                    {
                        path: "one-way-mp",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_ONEWAY");
                        },
                        label: (i18n: I18n) => i18n._(t`One-Way MP`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <OneByOneMP />
                            </Suspense>
                        ),
                    },
                    {
                        path: "turn-by-turn-mp",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_TURN_BY_TURN");
                        },
                        label: (i18n: I18n) => i18n._(t`Turn-By-Turn MP`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <TurnByTurnMP />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "social",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return session.playerId != null;
                },
                label: (i18n: I18n) => i18n._(t`Social`),
                icon: null,
                exact: true,
                redirect: "friends",
                routes: [
                    {
                        path: "friends",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_FRIENDS");
                        },
                        label: (i18n: I18n) => i18n._(t`Friends`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <PlayerFriends />
                            </Suspense>
                        ),
                    },
                    {
                        path: "groups",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_USER_GROUPS");
                        },
                        label: (i18n: I18n) => i18n._(t`Groups`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <PlayerGroups />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
];

export default routes;

// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Theme, createTheme } from "@mui/material";
// import { adaptV4Theme } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import createStyles from "@mui/styles/createStyles";
import { withI18n, withI18nProps } from "@lingui/react";
import { I18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "moment/locale/fr";

// Import types.
import PortalState from "types/store";
import EnvironmentInformation from "types/common/EnvironmentInformation";
import ApplicationInformation from "types/common/ApplicationInformation";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import Session from "types/common/Session";
import User from "types/common/User";

// Import components.
import { WithStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { Link, Typography } from "@mui/material";
import PortalUnauthenticated from "components/PortalUnauthenticated";
import PortalAuthenticated from "components/PortalAuthenticated";
import LoadingProgress from "components/common/widgets/LoadingProgress";
import IntercomWidget from "./common/widgets/Intercom";

// Import utilities.
import BrandingUtils from "utils/Branding";
import CookieConsentUtils from "utils/CookieConsent";

// Import the default Mui Theme  and Branding objects.
// import { defaultGlobalCSS } from "assets/data/defaultMuiTheme";
// import defaultMuiTheme from "assets/data/defaultMuiTheme";
import defaultMuiTheme_v5 from "assets/data/defaultMuiTheme_v5";
import { cssVariables as cssVariables_light } from "assets/data/defaultBranding-light";
import { cssVariables as cssVariables_dark } from "assets/data/defaultBranding-dark";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

interface STATE_PROPS {
    environmentInformation: EnvironmentInformation;
    applicationInformation: ApplicationInformation;
    themeConfiguration: ThemeConfiguration;
    session: Session;
    currentUser: User | null;
}
interface DISPATCH_PROPS {
    initializeApplication: (i18n: I18n) => void;
}
interface OWN_PROPS {}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps {}

const mapStateToProps = (state: PortalState) => {
    return {
        environmentInformation: state.environmentInformation,
        applicationInformation: state.applicationInformation,
        themeConfiguration: state.themeConfiguration,
        session: state.session,
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        initializeApplication: (i18n: I18n) =>
            dispatch({
                type: "initialization.initializeApplication",
                payload: {
                    i18n,
                    name: process.env.REACT_APP_NAME && process.env.REACT_APP_NAME.trim().length > 0 ? process.env.REACT_APP_NAME + " (" + process.env.NODE_ENV + ")" : "(" + process.env.NODE_ENV + ")",
                    prefix: process.env.REACT_APP_BUILD_PREFIX && process.env.REACT_APP_BUILD_PREFIX.trim().length > 0 ? process.env.REACT_APP_BUILD_PREFIX : null,
                    version: process.env.REACT_APP_VERSION && process.env.REACT_APP_VERSION.trim().length > 0 ? process.env.REACT_APP_VERSION : null,
                    build: process.env.REACT_APP_BUILD_NUMBER && process.env.REACT_APP_BUILD_NUMBER.trim().length > 0 ? process.env.REACT_APP_BUILD_NUMBER : null,
                },
            }),
    };
};

// Setup the Global Material-UI MUI Theme.
// const GlobalCss = withStyles(defaultGlobalCSS)(() => null);

// Customized MUI Theme global overrides (background, font, padding, margin and form controls).
// const muiTheme = createTheme(adaptV4Theme(defaultMuiTheme));
const muiTheme_v5 = createTheme(defaultMuiTheme_v5);

class Main extends React.PureComponent<PROPS> {
    componentDidMount() {
        const { i18n } = this.props;

        console.log("Main URL", window.location.pathname, window.location.search, window.location.hash);

        this.props.initializeApplication(i18n);
    }

    componentDidUpdate(prevProps: PROPS) {
        if (prevProps.applicationInformation.status !== "ready" && this.props.applicationInformation.status === "ready") {
            console.log("Portal-X is now READY");
        }
    }

    handleToastClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    render() {
        const { classes, environmentInformation, applicationInformation, session, themeConfiguration, currentUser } = this.props;

        // Get the currently active theme.
        const activeTheme = themeConfiguration.activeTheme;

        // Get the CSS stylesheet reference for the custom font of the active theme and mode (old-style CSS injection using "link" tag).
        const fontUrl = activeTheme && activeTheme[session.themeMode].fontUrl ? activeTheme[session.themeMode].fontUrl : null;

        // Get the CSS content for the active theme and mode (new-style CSS injections using "style" tag).
        const cssContent = activeTheme && activeTheme[session.themeMode].cssContent ? activeTheme[session.themeMode].cssContent : null;

        // Get the fallback CSS content for the default theme.
        // This should only occur when loading the page (or refreshing) and the default theme hasn't finished loading.
        // In other words, this fixes the flipping back and forth to a white screen when refreshing the browser.
        const defaultCSSContent = session.themeMode === "dark" ? BrandingUtils.generatePortalBrandingCSSContent(cssVariables_dark) : BrandingUtils.generatePortalBrandingCSSContent(cssVariables_light);

        // Extract some fields from the application information.
        const isLoadingBasicState = applicationInformation.loadingBasicState;
        const intercomEnabled = applicationInformation.intercomConfiguration.enabled;
        const intercomAppId = applicationInformation.intercomConfiguration.appId;
        const showIntercom = !isLoadingBasicState && intercomEnabled && intercomAppId != null;

        // Determine whether or not we render the PortalAuthenticated or PortalUnauthenticated component.
        let showAuthenticatedView = false;
        if (applicationInformation.status === "ready") {
            if (session.isAuthenticated && session.companyId) {
                showAuthenticatedView = true;
            }
        }

        // Render the main portal component.
        // This component will fill the entire window and inject the active theme CSS ref and optional content into the page.
        // In terms of branding, Material-UI has it's own branding support. We may use this in combination with
        // the direct injection of our custom CSS variables.
        return (
            <div id="main" className={classes.root}>
                <style>{defaultCSSContent}</style>

                {activeTheme && fontUrl && <link rel="stylesheet" type="text/css" href={fontUrl} />}

                {activeTheme && cssContent && <style>{cssContent}</style>}

                {/* <GlobalCss /> */}

                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={currentUser?.preferredLanguage ? currentUser.preferredLanguage : "en"}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={muiTheme_v5}>
                            {applicationInformation.status === "ready" && showAuthenticatedView && !applicationInformation.loadingInitialState && <PortalAuthenticated />}

                            {applicationInformation.status === "ready" && !showAuthenticatedView && !applicationInformation.loadingInitialState && <PortalUnauthenticated />}

                            {applicationInformation.status === "error" && (
                                <div className={"centered"} style={{ margin: 0 }}>
                                    <Typography style={{ fontSize: "1.5rem", textAlign: "center" }}>
                                        <Trans>An error occured during initialization... try refreshing the page. If this error persists, please contact support.</Trans>
                                    </Typography>

                                    {environmentInformation.supportEmail && (
                                        <Link data-id={"contact-support"} data-email={environmentInformation.supportEmail} style={{ marginTop: "2rem", fontSize: "1.5rem", textAlign: "center" }} href={"mailto:" + environmentInformation.supportEmail}>
                                            {environmentInformation.supportEmail}
                                        </Link>
                                    )}
                                </div>
                            )}

                            {applicationInformation.status && !["ready", "disabled", "error"].includes(applicationInformation.status) && (
                                <div className={"centered"} style={{ margin: 0 }}>
                                    <LoadingProgress hideLabel={true} />
                                </div>
                            )}

                            {applicationInformation.status && ["ready", "disabled", "error"].includes(applicationInformation.status) && applicationInformation.loadingInitialState && (
                                <div className={"centered"} style={{ margin: 0 }}>
                                    <LoadingProgress label={<Trans>Please Wait...</Trans>} />
                                </div>
                            )}

                            {CookieConsentUtils.isUserAccepted() && applicationInformation.status === "ready" && !applicationInformation.loadingInitialState && session.externalAuthenticationProvider && (
                                <div className={"centered"} style={{ margin: 0 }}>
                                    <LoadingProgress label={session.email ? <Trans>Completing SSO Authentication...</Trans> : <Trans>Triggering SSO Authentication...</Trans>} />
                                </div>
                            )}

                            {/* Intercom Messenger Container. */}
                            {!applicationInformation.loadingInitialState && showIntercom && currentUser && <IntercomWidget applicationInformation={applicationInformation} session={session} currentUser={currentUser} />}

                            {/* React Toastify Container. */}
                            <ToastContainer position={"top-right"} autoClose={5000} closeOnClick={false} pauseOnHover={true} pauseOnFocusLoss={true} hideProgressBar={true} newestOnTop={false} onClick={this.handleToastClick} />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </LocalizationProvider>
            </div>
        );
    }
}

// Styling for this component.
const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withI18n()(withStyles(styles)(Main)));

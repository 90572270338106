import React, { Suspense } from "react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import lazyRetry from "utils/LazyRetry";

import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import Session from "types/common/Session";
import User from "types/common/User";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalPrivilege, { hasReadAccess } from "types/common/PortalPrivilege";

import { DirectAPIContext, DirectAPIContextProvider } from "framework/contextDirectAPI";
import { DirectRTTContext, DirectRTTContextProvider } from "framework/contextDirectRTT";
import { DirectS2SContext, DirectS2SContextProvider } from "framework/contextDirectS2S";
import DashboardIcon from "@mui/icons-material/Speed";
import FavoritesIcon from "@mui/icons-material/StarOutline";

import LoadingProgress from "components/common/widgets/LoadingProgress";

const ApplicationIds = React.lazy(lazyRetry(() => import("components/screens/design/ApplicationIds")));
const AdvancedSettings = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdvancedSettings")));
const PeerPublish = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/PeerPublish")));
const Localization = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/Localization")));
const APIExplorer = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/APIExplorer")));
const S2SExplorer = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/S2SExplorer")));
const CloudCodeEditor = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/Scripts")));
const Deployment = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdminTools/Deployment")));
const CheckPoints = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdminTools/CheckPoints")));
const ConfigurationData = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdminTools/ConfigurationData")));
const LegacyFilesTransfer = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdminTools/LegacyFiles")));
const ApplicationCache = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdminTools/ApplicationCache")));
const DataDeletion = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AdminTools/DataDeletion")));
const Platforms = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/Platforms")));
const AppSecurity = React.lazy(lazyRetry(() => import("components/screens/design/Security/App")));
const UserSecurity = React.lazy(lazyRetry(() => import("components/screens/design/Security/User")));
const RedemptionCodeTypes = React.lazy(lazyRetry(() => import("components/screens/design/Marketplace/RedemptionCodes")));
const GlobalStatistics = React.lazy(lazyRetry(() => import("components/screens/monitoring/CloudData/GlobalStatistics")));
const GlobalEntities = React.lazy(lazyRetry(() => import("components/screens/monitoring/CloudData/GlobalEntities")));
const Groups = React.lazy(lazyRetry(() => import("components/screens/monitoring/Groups/Groups")));
const GroupEntities = React.lazy(lazyRetry(() => import("components/screens/monitoring/Groups/GroupEntities")));
const ErrorLogs = React.lazy(lazyRetry(() => import("components/screens/common/ErrorLogs")));
const UnreadableRequests = React.lazy(lazyRetry(() => import("components/screens/common/UnreadableRequests")));
const JobQueue = React.lazy(lazyRetry(() => import("components/screens/monitoring/Logs/JobQueue")));
const ServerLogs = React.lazy(lazyRetry(() => import("components/screens/monitoring/Logs/ServerLogs")));
const RedemptionCodes = React.lazy(lazyRetry(() => import("components/screens/monitoring/MarketPlace/RedemptionCodes")));
const GlobalTransactions = React.lazy(lazyRetry(() => import("components/screens/monitoring/MarketPlace/Transactions")));
const GlobalCustomEntity = React.lazy(lazyRetry(() => import("components/screens/monitoring/CloudData/CustomEntities/GlobalCustomEntity")));
const Leaderboards = React.lazy(lazyRetry(() => import("components/screens/monitoring/Gamification/Leaderboards")));
const VirtualCurrencies = React.lazy(lazyRetry(() => import("components/screens/design/Marketplace/VirtualCurrency")));
const Settings = React.lazy(lazyRetry(() => import("components/screens/design/Notifications/Settings")));
const ChatChannels = React.lazy(lazyRetry(() => import("components/screens/design/Messaging/ChatChannels")));
const ChatConfig = React.lazy(lazyRetry(() => import("components/screens/design/Messaging/Chat")));
const Messaging = React.lazy(lazyRetry(() => import("components/screens/design/Messaging/Messaging")));
const Presence = React.lazy(lazyRetry(() => import("components/screens/design/Messaging/Presence")));
const Products = React.lazy(lazyRetry(() => import("components/screens/design/Marketplace/Products")));
const Pacers = React.lazy(lazyRetry(() => import("components/screens/design/Leaderboards/Pacers")));
const Templates = React.lazy(lazyRetry(() => import("components/screens/design/Notifications/Templates")));
const CustomEntities = React.lazy(lazyRetry(() => import("components/screens/design/CloudData/CustomEntities")));
const GlobalEntityCollections = React.lazy(lazyRetry(() => import("components/screens/design/CloudData/GlobalEntities")));
const LobbyTypes = React.lazy(lazyRetry(() => import("components/screens/design/Multiplayer/Lobbies")));
const DivisionSets = React.lazy(lazyRetry(() => import("components/screens/design/Leaderboards/DivisionSets")));
const Segments = React.lazy(lazyRetry(() => import("components/screens/design/Segmentation/Segments")));
const ManageIntegrations = React.lazy(lazyRetry(() => import("components/screens/design/Integrations/ManageIntegrations")));
const AutoDeleteUsers = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/AutoDeleteUsers")));
const ArchiveSettings = React.lazy(lazyRetry(() => import("components/screens/design/CoreAppInfo/ArchiveSettings")));
const EmailAuthentication = React.lazy(lazyRetry(() => import("components/screens/design/Authentication/EmailAuthentication")));
const Passwords = React.lazy(lazyRetry(() => import("components/screens/design/Authentication/Passwords")));
const PeerServices = React.lazy(lazyRetry(() => import("components/screens/design/Integrations/PeerServices")));
const Matchmaking = React.lazy(lazyRetry(() => import("components/screens/design/Multiplayer/Matchmaking")));
const Automated = React.lazy(lazyRetry(() => import("components/screens/design/Promotions/Automated")));
const Scheduled = React.lazy(lazyRetry(() => import("components/screens/design/Promotions/Scheduled")));
const WebServices = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/WebServices")));
const WebHooks = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/WebHooks")));
const Achievements = React.lazy(lazyRetry(() => import("components/screens/design/Gamification/Achievements")));
const XPLevels = React.lazy(lazyRetry(() => import("components/screens/design/Gamification/XPLevels")));
const StatisticsEvents = React.lazy(lazyRetry(() => import("components/screens/design/Gamification/StatisticsEvents")));
const APIBlocking = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/APIBlocking")));
const APIHooks = React.lazy(lazyRetry(() => import("components/screens/design/CloudCode/APIHooks")));
const ExternalIdentity = React.lazy(lazyRetry(() => import("components/screens/design/Authentication/ExternalIdentity")));
const SharedAccounts = React.lazy(lazyRetry(() => import("components/screens/design/Authentication/SharedAccounts")));
const Blockchain = React.lazy(lazyRetry(() => import("components/screens/design/Integrations/Blockchain")));
const GlobalProperties = React.lazy(lazyRetry(() => import("components/screens/design/CloudData/GlobalProperties")));
const DesignGlobalStatistics = React.lazy(lazyRetry(() => import("components/screens/design/CloudData/GlobalStatistics")));
const DesignUserStatistics = React.lazy(lazyRetry(() => import("components/screens/design/CloudData/UserStatistics")));
const MyServers = React.lazy(lazyRetry(() => import("components/screens/design/Servers/MyServers")));
const GlobalFiles = React.lazy(lazyRetry(() => import("components/screens/design/Files/GlobalFiles")));
const LegacyFiles = React.lazy(lazyRetry(() => import("components/screens/design/Files/LegacyFiles")));
const Quests = React.lazy(lazyRetry(() => import("components/screens/design/Gamification/Quests")));
const Milestones = React.lazy(lazyRetry(() => import("components/screens/design/Gamification/Milestones")));
const TournamentConfigs = React.lazy(lazyRetry(() => import("components/screens/design/Leaderboards/TournamentTemplates")));
const LeaderboardConfigs = React.lazy(lazyRetry(() => import("components/screens/design/Leaderboards/LeaderboardConfigs")));
const APIErrors = React.lazy(lazyRetry(() => import("components/screens/reporting/Usage/APIErrors")));
const Core = React.lazy(lazyRetry(() => import("components/screens/reporting/Analytics/Core")));
const ProductTransactions = React.lazy(lazyRetry(() => import("components/screens/reporting/Analytics/ProductTransactions")));
const Languages = React.lazy(lazyRetry(() => import("components/screens/reporting/Analytics/Languages")));
const CountryAnalytics = React.lazy(lazyRetry(() => import("components/screens/reporting/Analytics/Country")));
const AppStoreAnalytics = React.lazy(lazyRetry(() => import("components/screens/reporting/Analytics/AppStore")));
const Screens = React.lazy(lazyRetry(() => import("components/common/Screens")));
const Properties = React.lazy(lazyRetry(() => import("components/common/Properties")));
const AppProfile = React.lazy(lazyRetry(() => import("components/screens/reporting/Usage/AppProfile")));
const APIUsageComponent = React.lazy(lazyRetry(() => import("components/screens/reporting/Usage/APIUsage")));
const ScriptAnalysisComponent = React.lazy(lazyRetry(() => import("components/screens/reporting/Usage/ScriptAnalysis")));
const MonthlyMetrics = React.lazy(lazyRetry(() => import("components/screens/reporting/Usage/MonthlyMetrics")));
const ExportUsers = React.lazy(lazyRetry(() => import("components/screens/reporting/ExportUsers")));
const GroupTypes = React.lazy(lazyRetry(() => import("components/screens/design/Groups/GroupTypes")));
const Catalog = React.lazy(lazyRetry(() => import("components/screens/design/Marketplace/ItemCatalog")));
const Dashboard = React.lazy(lazyRetry(() => import("components/screens/appDashboard")));
const UserBrowserSettings = React.lazy(lazyRetry(() => import("components/screens/design/Portal/UserBrowserSettings")));
const UserSummaryConfiguration = React.lazy(lazyRetry(() => import("components/screens/design/Portal/UserSummaryConfiguration")));
const MonitorMatchmaking = React.lazy(lazyRetry(() => import("components/screens/monitoring/Multiplayer/Matchmaking")));
const HostedServer = React.lazy(lazyRetry(() => import("components/screens/monitoring/Multiplayer/HostedServer")));
const Retention = React.lazy(lazyRetry(() => import("components/screens/reporting/Analytics/Retention")));
const SecurityLogs = React.lazy(lazyRetry(() => import("components/screens/common/SecurityLogs")));

const routes: PortalRouteDefinition[] = [
    {
        path: "app",
        hasAccess: (session: Session, _currentUser: User | null, _availableCompanies: TeamInfo[], _availableApps: AppInfo[], _availablePrivileges: PortalPrivilege[]) => {
            return session.appId != null;
        },
        label: (i18n: I18n) => i18n._(t`App`),
        icon: null,
        exact: true,
        redirect: "dashboard",
        routes: [
            {
                path: "dashboard",
                label: (i18n: I18n) => i18n._(t`Dashboard`),
                icon: <DashboardIcon />,
                exact: true,
                component: (
                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                        <Dashboard />
                    </Suspense>
                ),
            },
            {
                path: "favorites",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return false;
                },
                label: (i18n: I18n) => i18n._(t`Favorites`),
                icon: <FavoritesIcon />,
                exact: true,
                routes: [],
            },
            {
                path: "design",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return hasReadAccess(availablePrivileges, "DESIGN_TAB");
                },
                label: (i18n: I18n) => i18n._(t`Design`),
                icon: null,
                exact: true,
                redirect: "core-app-info",
                routes: [
                    {
                        path: "core-app-info",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_COREAPP");
                        },
                        label: (i18n: I18n) => i18n._(t`Core App Info`),
                        icon: null,
                        exact: true,
                        redirect: "application-ids",
                        routes: [
                            {
                                path: "application-ids",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_APPIDS");
                                },
                                label: (i18n: I18n) => i18n._(t`Application IDs`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ApplicationIds />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "advanced-settings",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADVANCED");
                                },
                                label: (i18n: I18n) => i18n._(t`Advanced Settings`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <AdvancedSettings />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "auto-delete-users",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_AUTO_DELETE");
                                },
                                label: (i18n: I18n) => i18n._(t`Auto-Delete Users`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <AutoDeleteUsers />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "archive-settings",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_USER_ARCHIVE");
                                },
                                label: (i18n: I18n) => i18n._(t`Archive Settings`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ArchiveSettings />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "localization",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_LOCALIZATION");
                                },
                                label: (i18n: I18n) => i18n._(t`Localization`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Localization />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "peer-publish",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PEER_PUBLISH");
                                },
                                label: (i18n: I18n) => i18n._(t`Peer Publish`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <PeerPublish />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "platforms",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PLATFORMS");
                                },
                                label: (i18n: I18n) => i18n._(t`Platforms`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Platforms />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "admin-tools",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_ADMIN");
                        },
                        label: (i18n: I18n) => i18n._(t`Admin Tools`),
                        icon: null,
                        exact: true,
                        redirect: "cache",
                        routes: [
                            {
                                path: "cache",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADMIN_APP_CACHE");
                                },
                                label: (i18n: I18n) => i18n._(t`Cache`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ApplicationCache />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "checkpoints",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADMIN_CHECKPOINTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Checkpoints`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <CheckPoints />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "configuration-data",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADMIN_CONFIG_DATA");
                                },
                                label: (i18n: I18n) => i18n._(t`Configuration Data`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ConfigurationData />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "data-deletion",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADMIN_DATA_DELETION");
                                },
                                label: (i18n: I18n) => i18n._(t`Data Deletion`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <DataDeletion />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "deployment",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADMIN_DEPLOYMENT");
                                },
                                label: (i18n: I18n) => i18n._(t`Deployment`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Deployment />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "legacy-files-transfer",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ADMIN_LEGACY_FILES");
                                },
                                label: (i18n: I18n) => i18n._(t`Legacy Files Transfer`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <LegacyFilesTransfer />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "authentication",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_AUTH");
                        },
                        label: (i18n: I18n) => i18n._(t`Authentication`),
                        icon: null,
                        exact: true,
                        redirect: "email-authentication",
                        routes: [
                            {
                                path: "email-authentication",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_AUTH_EMAIL");
                                },
                                label: (i18n: I18n) => i18n._(t`Email Authentication`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <EmailAuthentication />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "external",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_AUTH_EXTERNAL");
                                },
                                label: (i18n: I18n) => i18n._(t`External`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ExternalIdentity />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "passwords",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_AUTH_PASSWORDS");
                                },
                                label: (i18n: I18n) => i18n._(t`Passwords`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Passwords />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "shared-accounts",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_AUTH_SHARED");
                                },
                                label: (i18n: I18n) => i18n._(t`Shared Accounts`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <SharedAccounts />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "cloud-code",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_CLOUD_CODE");
                        },
                        label: (i18n: I18n) => i18n._(t`Cloud Code`),
                        icon: null,
                        exact: true,
                        redirect: "api-explorer",
                        routes: [
                            {
                                path: "api-explorer",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_API_EXPLORER");
                                },
                                label: (i18n: I18n) => i18n._(t`API Explorer`),
                                icon: null,
                                exact: true,
                                component: (
                                    <DirectAPIContextProvider>
                                        <DirectRTTContextProvider>
                                            <DirectAPIContext.Consumer>
                                                {(directAPI) => (
                                                    <DirectRTTContext.Consumer>
                                                        {(directRTT) => (
                                                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                                                <APIExplorer directAPI={directAPI} directRTT={directRTT} />
                                                            </Suspense>
                                                        )}
                                                    </DirectRTTContext.Consumer>
                                                )}
                                            </DirectAPIContext.Consumer>
                                        </DirectRTTContextProvider>
                                    </DirectAPIContextProvider>
                                ),
                            },
                            {
                                path: "scripts",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_SCRIPT_FOLDERS");
                                },
                                label: (i18n: I18n) => i18n._(t`Scripts`),
                                icon: null,
                                exact: true,
                                component: (
                                    <DirectAPIContextProvider>
                                        <DirectAPIContext.Consumer>
                                            {(directAPI) => (
                                                <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                                    <CloudCodeEditor directAPI={directAPI} />
                                                </Suspense>
                                            )}
                                        </DirectAPIContext.Consumer>
                                    </DirectAPIContextProvider>
                                ),
                                permitFullscreen: true,
                            },
                            {
                                path: "api-blocking",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_API_BLOCKING");
                                },
                                label: (i18n: I18n) => i18n._(t`API Blocking`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <APIBlocking />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "api-hooks",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_API_HOOKS");
                                },
                                label: (i18n: I18n) => i18n._(t`API Hooks`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <APIHooks />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "web-services",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_WEB_SERVICES");
                                },
                                label: (i18n: I18n) => i18n._(t`Web Services`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <WebServices />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "s2s-explorer",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_S2S_EXPLORER");
                                },
                                label: (i18n: I18n) => i18n._(t`S2S Explorer`),
                                icon: null,
                                exact: true,
                                component: (
                                    <DirectS2SContextProvider>
                                        <DirectRTTContextProvider>
                                            <DirectS2SContext.Consumer>
                                                {(directS2S) => (
                                                    <DirectRTTContext.Consumer>
                                                        {(directRTT) => (
                                                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                                                <S2SExplorer directS2S={directS2S} directRTT={directRTT} />
                                                            </Suspense>
                                                        )}
                                                    </DirectRTTContext.Consumer>
                                                )}
                                            </DirectS2SContext.Consumer>
                                        </DirectRTTContextProvider>
                                    </DirectS2SContextProvider>
                                ),
                            },
                            {
                                path: "web-hooks",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_WEB_HOOKS");
                                },
                                label: (i18n: I18n) => i18n._(t`Web Hooks`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <WebHooks />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "cloud-data",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_CLOUD_DATA");
                        },
                        label: (i18n: I18n) => i18n._(t`Cloud Data`),
                        icon: null,
                        exact: true,
                        redirect: "custom-entities",
                        routes: [
                            {
                                path: "custom-entities",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_CUSTOM_ENTITIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Custom Entities`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <CustomEntities />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "global-entities",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_GLOBAL_ENTITIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Global Entities`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalEntityCollections />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "global-properties",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_GLOBAL_PROPERTIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Global Properties`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalProperties />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "global-statistics",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_GLOBAL_STATS");
                                },
                                label: (i18n: I18n) => i18n._(t`Global Statistics`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <DesignGlobalStatistics />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "user-statistics",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_USER_STATS");
                                },
                                label: (i18n: I18n) => i18n._(t`User Statistics`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <DesignUserStatistics />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "files",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_GLOBAL_FILES") || hasReadAccess(availablePrivileges, "DESIGN_FILES");
                        },
                        label: (i18n: I18n) => i18n._(t`Files`),
                        icon: null,
                        exact: true,
                        redirect: "global-files",
                        routes: [
                            {
                                path: "global-files",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_GLOBAL_FILES");
                                },
                                label: (i18n: I18n) => i18n._(t`Global Files`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalFiles />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "legacy-files",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_FILES");
                                },
                                label: (i18n: I18n) => i18n._(t`Legacy Files`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <LegacyFiles />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "gamification",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            const targetApp = availableApps.find((item) => item.appId === session.appId);

                            return targetApp?.isGamificationEnabled === true && hasReadAccess(availablePrivileges, "DESIGN_SECTION_GAMIFICATION");
                        },
                        label: (i18n: I18n) => i18n._(t`Gamification`),
                        icon: null,
                        exact: true,
                        redirect: "achievements",
                        routes: [
                            {
                                path: "achievements",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ACHIEVEMENTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Achievements`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Achievements />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "milestones",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MILESTONES");
                                },
                                label: (i18n: I18n) => i18n._(t`Milestones`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Milestones />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "quests/:questId?",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_QUESTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Quests`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Quests />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "statistics-events",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_STATS_EVENTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Statistics Events`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <StatisticsEvents />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "xp-levels",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_XP_LEVELS");
                                },
                                label: (i18n: I18n) => i18n._(t`XP Levels`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <XPLevels />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "groups",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_GROUPS");
                        },
                        label: (i18n: I18n) => i18n._(t`Groups`),
                        icon: null,
                        exact: true,
                        redirect: "group-types",
                        routes: [
                            {
                                path: "group-types",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_GROUP_TYPES");
                                },
                                label: (i18n: I18n) => i18n._(t`Group Types`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GroupTypes />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "integrations",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_INTEGRATIONS");
                        },
                        label: (i18n: I18n) => i18n._(t`Integrations`),
                        icon: null,
                        exact: true,
                        redirect: "blockchain",
                        routes: [
                            {
                                path: "blockchain",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MANAGE_BLOCKCHAINS");
                                },
                                label: (i18n: I18n) => i18n._(t`Blockchain`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Blockchain />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "manage-integrations",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MANAGE_INTEGRATIONS");
                                },
                                label: (i18n: I18n) => i18n._(t`Manage Integrations`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ManageIntegrations />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "peer-services",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MANAGE_PEER_SERVICES");
                                },
                                label: (i18n: I18n) => i18n._(t`Peer Services`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <PeerServices />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "leaderboards",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            const targetApp = availableApps.find((item) => item.appId === session.appId);

                            return targetApp?.isGamificationEnabled === true && hasReadAccess(availablePrivileges, "DESIGN_SECTION_LEADERBOARDS");
                        },
                        label: (i18n: I18n) => i18n._(t`Leaderboards`),
                        icon: null,
                        exact: true,
                        redirect: "division-sets",
                        routes: [
                            {
                                path: "division-sets",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_DIVISION_SETS");
                                },
                                label: (i18n: I18n) => i18n._(t`Division Sets`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <DivisionSets />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "leaderboard-configs",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_LEADERBOARD_CONFIG");
                                },
                                label: (i18n: I18n) => i18n._(t`Leaderboard Configs`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <LeaderboardConfigs />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "pacers",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PACERS");
                                },
                                label: (i18n: I18n) => i18n._(t`Pacers`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Pacers />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "tournament-templates",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_TOURNAMENT_CONFIG");
                                },
                                label: (i18n: I18n) => i18n._(t`Tournament Templates`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <TournamentConfigs />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "marketplace",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_MARKETPLACE");
                        },
                        label: (i18n: I18n) => i18n._(t`Marketplace`),
                        icon: null,
                        exact: true,
                        redirect: "item-catalog",
                        routes: [
                            {
                                path: "item-catalog",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_ITEMMGMT_CATALOG");
                                },
                                label: (i18n: I18n) => i18n._(t`Item Catalog`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Catalog />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "products",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PRODUCTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Products`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Products />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "redemption-codes",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_REDEMPTION_CODES");
                                },
                                label: (i18n: I18n) => i18n._(t`Redemption Codes`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <RedemptionCodeTypes />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "virtual-currencies",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_VIRTUAL_CURRENCIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Virtual Currencies`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <VirtualCurrencies />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "messaging",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_MESSAGING");
                        },
                        label: (i18n: I18n) => i18n._(t`Messaging`),
                        icon: null,
                        exact: true,
                        redirect: "chat",
                        routes: [
                            {
                                path: "chat",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MESSAGING_CHAT");
                                },
                                label: (i18n: I18n) => i18n._(t`Chat`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ChatConfig />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "chat-channels",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MESSAGING_CHAT_GLOBAL");
                                },
                                label: (i18n: I18n) => i18n._(t`Chat Channels`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ChatChannels />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "messaging",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MESSAGING_MESSAGES");
                                },
                                label: (i18n: I18n) => i18n._(t`Messaging`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Messaging />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "presence",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MESSAGING_PRESENCE");
                                },
                                label: (i18n: I18n) => i18n._(t`Presence`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Presence />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "multiplayer",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            const targetApp = availableApps.find((item) => item.appId === session.appId);

                            return targetApp?.isGamificationEnabled === true && hasReadAccess(availablePrivileges, "DESIGN_SECTION_MULTIPLAYER");
                        },
                        label: (i18n: I18n) => i18n._(t`Multiplayer`),
                        icon: null,
                        exact: true,
                        redirect: "lobbies",
                        routes: [
                            {
                                path: "lobbies",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_LOBBIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Lobbies`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <LobbyTypes />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "matchmaking",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_MATCHMAKING");
                                },
                                label: (i18n: I18n) => i18n._(t`Matchmaking`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Matchmaking />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "notifications",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_NOTIFICATIONS");
                        },
                        label: (i18n: I18n) => i18n._(t`Notifications`),
                        icon: null,
                        exact: true,
                        redirect: "settings",
                        routes: [
                            {
                                path: "settings",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PUSH_SETTINGS");
                                },
                                label: (i18n: I18n) => i18n._(t`Settings`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Settings />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "templates",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_TEMPLATES");
                                },
                                label: (i18n: I18n) => i18n._(t`Templates`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Templates />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "portal",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_PORTAL");
                        },
                        label: (i18n: I18n) => i18n._(t`Portal`),
                        icon: null,
                        exact: true,
                        redirect: "user-browser-settings",
                        routes: [
                            {
                                path: "user-browser-settings",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PORTAL_USERBROWSER");
                                },
                                label: (i18n: I18n) => i18n._(t`User Browser Settings`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <UserBrowserSettings />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "user-summary-configuration",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_PORTAL_USERSUMMARY");
                                },
                                label: (i18n: I18n) => i18n._(t`User Summary Configuration`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <UserSummaryConfiguration />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "promotions",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_PROMOTIONS");
                        },
                        label: (i18n: I18n) => i18n._(t`Promotions`),
                        icon: null,
                        exact: true,
                        redirect: "automated",
                        routes: [
                            {
                                path: "automated",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_AUTO_PROMOTIONS");
                                },
                                label: (i18n: I18n) => i18n._(t`Automated`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Automated />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "scheduled",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_SCHEDULED_PROMOTIONS");
                                },
                                label: (i18n: I18n) => i18n._(t`Scheduled`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Scheduled />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "security",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_SECURITY");
                        },
                        label: (i18n: I18n) => i18n._(t`Security`),
                        icon: null,
                        exact: true,
                        redirect: "app",
                        routes: [
                            {
                                path: "app",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_SECURITY_APP");
                                },
                                label: (i18n: I18n) => i18n._(t`App`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <AppSecurity />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "user",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_SECURITY_USER");
                                },
                                label: (i18n: I18n) => i18n._(t`User`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <UserSecurity />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "segmentation",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_SECTION_SEGMENTS");
                        },
                        label: (i18n: I18n) => i18n._(t`Segmentation`),
                        icon: null,
                        exact: true,
                        redirect: "segments",
                        routes: [
                            {
                                path: "segments",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_SEGMENTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Segments`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Segments />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "servers",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "DESIGN_S2S_CONFIG");
                        },
                        label: (i18n: I18n) => i18n._(t`Servers`),
                        icon: null,
                        exact: true,
                        redirect: "my-servers",
                        routes: [
                            {
                                path: "my-servers",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_S2S_CONFIG");
                                },
                                label: (i18n: I18n) => i18n._(t`My Servers`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <MyServers />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "super",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return session.isSuper === true;
                        },
                        label: (i18n: I18n) => i18n._(t`Super`),
                        icon: null,
                        exact: true,
                        redirect: "screens",
                        routes: [
                            {
                                path: "screens",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_BRAINCLOUD_SCREENS");
                                },
                                label: (i18n: I18n) => i18n._(t`Screens`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Screens context={"app"} />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "property-overrides",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "DESIGN_BRAINCLOUD_PROPERTIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Property Overrides`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Properties context={"app"} />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "global",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_SECTION");
                },
                label: (i18n: I18n) => i18n._(t`Global`),
                icon: null,
                exact: true,
                redirect: "global-data",
                routes: [
                    {
                        path: "global-data",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_SECTION_DATA");
                        },
                        label: (i18n: I18n) => i18n._(t`Global Data`),
                        icon: null,
                        exact: true,
                        redirect: "custom-entities",
                        routes: [
                            {
                                path: "custom-entities",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_CUSTOM_ENTITIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Custom Entities`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalCustomEntity />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "global-entities",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_ENTITIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Global Entities`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalEntities />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "global-statistics",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_STATS");
                                },
                                label: (i18n: I18n) => i18n._(t`Global Statistics`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalStatistics />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "leaderboards",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            const targetApp = availableApps.find((item) => item.appId === session.appId);

                            return targetApp?.isGamificationEnabled === true && hasReadAccess(availablePrivileges, "MONITOR_LEADERBOARDS");
                        },
                        label: (i18n: I18n) => i18n._(t`Leaderboards`),
                        icon: null,
                        exact: true,
                        redirect: "leaderboards",
                        routes: [
                            {
                                path: "leaderboards",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_LEADERBOARDS");
                                },
                                label: (i18n: I18n) => i18n._(t`Leaderboards`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Leaderboards />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "logs",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return (
                                hasReadAccess(availablePrivileges, "MONITOR_JOB_QUEUE") ||
                                hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_LOGS") ||
                                hasReadAccess(availablePrivileges, "MONITOR_SERVER_REQUESTS") ||
                                hasReadAccess(availablePrivileges, "MONITOR_UNREADABLE_REQUESTS")
                            );
                        },
                        label: (i18n: I18n) => i18n._(t`Logs`),
                        icon: null,
                        exact: true,
                        redirect: "job-queue",
                        routes: [
                            {
                                path: "job-queue",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_JOB_QUEUE");
                                },
                                label: (i18n: I18n) => i18n._(t`Job Queue`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <JobQueue />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "recent-errors",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_LOGS");
                                },
                                label: (i18n: I18n) => i18n._(t`Recent Errors`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ErrorLogs context={"app"} />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "security-logs",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_SECURITY_LOGS");
                                },
                                label: (i18n: I18n) => i18n._(t`Security Logs`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <SecurityLogs context={"app"} />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "server-logs",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_SERVER_REQUESTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Server Logs`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ServerLogs />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "unreadable-requests",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_UNREADABLE_REQUESTS");
                                },
                                label: (i18n: I18n) => i18n._(t`Unreadable Requests`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <UnreadableRequests context={"app"} />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "marketplace",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_REDEMPTION_CODES") || hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_TRANSACTIONS");
                        },
                        label: (i18n: I18n) => i18n._(t`Marketplace`),
                        icon: null,
                        exact: true,
                        redirect: "redemption-codes",
                        routes: [
                            {
                                path: "redemption-codes",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_REDEMPTION_CODES");
                                },
                                label: (i18n: I18n) => i18n._(t`Redemption Codes`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <RedemptionCodes />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "transactions",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_TRANSACTIONS");
                                },
                                label: (i18n: I18n) => i18n._(t`Transactions`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <GlobalTransactions />
                                    </Suspense>
                                ),
                            },
                            // {
                            //     path: "disputes",
                            //     hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            //         return hasReadAccess(availablePrivileges, "MONITOR_DISPUTES");
                            //     },
                            //     label: (i18n: I18n) => i18n._(t`Disputes`),
                            //     icon: null,
                            //     exact: true,
                            //     component: null,
                            // },
                        ],
                    },
                    {
                        path: "multiplayer",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_MULTIPLAYER_MATCHMAKING");
                        },
                        label: (i18n: I18n) => i18n._(t`Multiplayer`),
                        icon: null,
                        exact: true,
                        redirect: "matchmaking",
                        routes: [
                            {
                                path: "matchmaking",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_MULTIPLAYER_MATCHMAKING");
                                },
                                label: (i18n: I18n) => i18n._(t`Matchmaking Monitor`),
                                icon: null,
                                exact: true,
                                component: (
                                    <DirectRTTContextProvider>
                                        <DirectRTTContext.Consumer>
                                            {(directRTT) => (
                                                <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                                    <MonitorMatchmaking directRTT={directRTT} />
                                                </Suspense>
                                            )}
                                        </DirectRTTContext.Consumer>
                                    </DirectRTTContextProvider>
                                ),
                            },
                            {
                                path: "hosted-servers",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "MONITOR_GLOBAL_MULTIPLAYER_HOSTING");
                                },
                                label: (i18n: I18n) => i18n._(t`Server Monitor`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <HostedServer />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "groups",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return hasReadAccess(availablePrivileges, "MONITOR_GROUP_SECTION");
                },
                label: (i18n: I18n) => i18n._(t`Groups`),
                icon: null,
                exact: true,
                redirect: "groups",
                routes: [
                    {
                        path: "groups/:companyId?/:appId?/:groupId?",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_GROUP_GROUPS");
                        },
                        label: (i18n: I18n) => i18n._(t`Groups`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <Groups />
                            </Suspense>
                        ),
                    },
                    {
                        path: "group-entities",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "MONITOR_GROUP_ENTITIES");
                        },
                        label: (i18n: I18n) => i18n._(t`Group Entities`),
                        icon: null,
                        exact: true,
                        component: (
                            <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                <GroupEntities />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: "reports",
                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                    return hasReadAccess(availablePrivileges, "REPORT_SECTION");
                },
                label: (i18n: I18n) => i18n._(t`Reports`),
                icon: null,
                exact: true,
                redirect: "analytics",
                routes: [
                    {
                        path: "analytics",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "REPORT_DASH_ANALYTICS") || hasReadAccess(availablePrivileges, "REPORT_ANALYTICS_TRANSACTIONS");
                        },
                        label: (i18n: I18n) => i18n._(t`Analytics`),
                        icon: null,
                        exact: true,
                        redirect: "core",
                        routes: [
                            {
                                path: "core",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_DASH_ANALYTICS");
                                },
                                label: (i18n: I18n) => i18n._(t`App`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Core />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "country",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_ANALYTICS_COUNTRIES");
                                },
                                label: (i18n: I18n) => i18n._(t`Country`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <CountryAnalytics />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "languages",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_ANALYTICS_LANGUAGES");
                                },
                                label: (i18n: I18n) => i18n._(t`Language`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Languages />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "product",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_ANALYTICS_TRANSACTIONS");
                                },
                                label: (i18n: I18n) => i18n._(t`Product`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ProductTransactions />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "retention",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_ANALYTICS_RETENTION");
                                },
                                label: (i18n: I18n) => i18n._(t`Retention`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <Retention />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "app-store",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_ANALYTICS_APPSTORES");
                                },
                                label: (i18n: I18n) => i18n._(t`Store`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <AppStoreAnalytics />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "usage",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return (
                                hasReadAccess(availablePrivileges, "REPORT_API_USAGE") ||
                                hasReadAccess(availablePrivileges, "REPORT_ERROR_ANALYTICS") ||
                                hasReadAccess(availablePrivileges, "REPORT_APP_METRICS") ||
                                hasReadAccess(availablePrivileges, "REPORT_DATA_USAGE") ||
                                hasReadAccess(availablePrivileges, "REPORT_SCRIPT_USAGE")
                            );
                        },
                        label: (i18n: I18n) => i18n._(t`Usage`),
                        icon: null,
                        exact: true,
                        redirect: "app-usage",
                        routes: [
                            {
                                path: "api-usage",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_API_USAGE");
                                },
                                label: (i18n: I18n) => i18n._(t`API Usage`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <APIUsageComponent />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "api-errors",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_ERROR_ANALYTICS");
                                },
                                label: (i18n: I18n) => i18n._(t`API Errors`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <APIErrors />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "monthly-metrics",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_MONTHLY_USAGE");
                                },
                                label: (i18n: I18n) => i18n._(t`Monthly History`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <MonthlyMetrics />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "profile",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_APP_METRICS");
                                },
                                label: (i18n: I18n) => i18n._(t`Profile`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <AppProfile />
                                    </Suspense>
                                ),
                            },
                            {
                                path: "data-usage",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_DATA_USAGE");
                                },
                                label: (i18n: I18n) => i18n._(t`Data Usage`),
                                icon: null,
                                exact: true,
                                component: null,
                            },
                            {
                                path: "script-usage",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_SCRIPT_USAGE");
                                },
                                label: (i18n: I18n) => i18n._(t`Script Usage`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ScriptAnalysisComponent />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                    {
                        path: "exports",
                        hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                            return hasReadAccess(availablePrivileges, "REPORT_EXPORT_USERS");
                        },
                        label: (i18n: I18n) => i18n._(t`Exports`),
                        icon: null,
                        exact: true,
                        redirect: "export-users",
                        routes: [
                            {
                                path: "export-users",
                                hasAccess: (session: Session, currentUser: User | null, availableCompanies: TeamInfo[], availableApps: AppInfo[], availablePrivileges: PortalPrivilege[]) => {
                                    return hasReadAccess(availablePrivileges, "REPORT_EXPORT_USERS");
                                },
                                label: (i18n: I18n) => i18n._(t`Export Users`),
                                icon: null,
                                exact: true,
                                component: (
                                    <Suspense fallback={<LoadingProgress hideLabel={true} indicatorWidth={"1em"} />}>
                                        <ExportUsers />
                                    </Suspense>
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default routes;
